<template>
  <form action="" class="reset-pass__form" @submit.prevent="resetPassword()">
    <h2 class="reset-pass__form-heading">Réinitialistion du mot de passe</h2>
    <p class="reset-pass__form-paragraph">Veuillez s'il vous plaît entrer un nouveau mot de passe différent du dernier mot de passe.</p>
    <div class="reset-pass__form-group">
      <div
      :class="{'reset-pass__form-item':true, 'reset-pass__form-item--error':v$.password.$errors.length}"
      >
        <span class="reset-pass__form-item-notice">Requis *</span>
        <input 
          type="password" 
          class="reset-pass__form-item-input" 
          placeholder="Entrer votre mot de passe" 
          :required="true" 
          v-model="payload.password" 
          @blur="v$.password.$touch"
        />
      </div>
      <div class="reset-pass__form-item-error" v-for="(error, index) of v$.password.$errors" :key="index">
          {{ error.$message }}
      </div>
    </div>
    <div class="reset-pass__form-group">
      <div
      :class="{'reset-pass__form-item':true, 'reset-pass__form-item--error':v$.passwordConfirmation.$errors.length}"
      >
        <span class="reset-pass__form-item-notice">Requis *</span>
        <input 
          type="password" 
          class="reset-pass__form-item-input" 
          placeholder="Confirmer votre mot de passe" 
          :required="true" 
          v-model="payload.passwordConfirmation" 
          @blur="v$.passwordConfirmation.$touch"
        />
      </div>
      <div class="reset-pass__form-item-error" v-for="(error, index) of v$.passwordConfirmation.$errors" :key="index">
          {{ error.$message }}
      </div>
      <div class="reset-pass__form-item-error" v-if="sameAsError?.length">
          {{sameAsError}}
      </div>
    </div>
    <atom-button 
      class="reset-pass__form-button" 
      variant="primary md full"
      type="submit"
    >
      Réinitialiser le mot de passe 
    </atom-button>
  </form>
  <div class="reset-pass__quote">
    <h1 class="reset-pass__quote-content">"Un manque de transparence se traduit par la méfiance et un profond sentiment d'insécurité."</h1>
    <span class="reset-pass__quote-author">- Dalai Lama, The Telegraph, 13 Mai 2012.</span>
  </div>
</template>
<script setup>
import {ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, sameAs } from '@vuelidate/validators';
const {params} = useRoute();
const router = useRouter();
const {dispatch} = useStore();
const payload = ref({
  password: '',
  passwordConfirmation: '',
  token: params.token,
  userId: params.userId
});
const sameAsError = ref("");
const password = helpers.regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/);
const rules = {
  password: {
    required,
    password: helpers.withMessage("Au minimum 8 caractères, au moins une lettre et un chiffre.", password)
  },
  passwordConfirmation: {
    required
  }
}
const v$ = useVuelidate(rules, payload);

const resetPassword = async () => {
  if(payload.value.password !== payload.value.passwordConfirmation){
    sameAsError.value = "Les mots de passe ne correspondent pas.";
  }
  const isFormValid = !v$.value.$invalid;
    if(!isFormValid){
      v$.value.$touch();
    }
    // Form is valid
    else{
      try {
        await dispatch("users/resetPassword", payload.value);
        router.push({name: 'Login'});
      } catch (err) {
        console.log(err);
      }
    }
}
</script>


<style lang="scss" scoped>
  .reset-pass{
    &__form{
      @apply w-full max-w-[500px] bg-white
      py-5 px-2 sm:px-4 md:px-6 lg:px-[80px]
      rounded-md;
      &-group{
        @apply relative flex;
      }
      &-item {
        @apply mb-5 w-full;
        &--error{
          .register__form-item{
            &-input{
              @apply border-red-600 border-2 
              focus:ring-0;
            }
          }
        }
        &-notice {
          @apply text-[10px] text-[#C4C4C4] inline-block mb-[3px];
        }
        &-input {
          @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md;
        }
        &-error{
          @apply absolute bottom-[3px] right-0 text-xs text-red-600;
        }
      }
      &-error {
        @apply text-red-600 mb-1;
      }
      &-heading{
        @apply w-full font-bold
         text-center text-base
         mb-5;
      }
      &-paragraph{
        @apply text-base text-center
        mb-[68px];
      }
      &-input{
        @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md mb-4;
      }
      &-button{
        @apply mb-5 mt-5 py-2 font-medium;
      }
      &-link{
        @apply text-base text-primary font-medium leading-4 inline-block;
        &--centered{
          @apply w-full text-center;
        }
      }
    }
    &__quote{
      @apply hidden lg:block p-5 bg-black/40 max-w-[600px] w-full;
      &-content{
        @apply text-white font-medium 
        text-[30px] xl:text-[36px];
      }
      &-author{
        @apply text-white font-medium text-sm;
      }
    }
  }
</style>